<template>
  <div class="serivices">
    <div class="mask" v-if="show" @click.stop="hide">
      <!-- 遮罩层的内容 -->
      <div class="pop-content" @click.stop="">
        <div class="pop-header">REQUESTING AN INSPECTION HASNEVER BEEN EASIER!</div>
        <div class="pop-form">
          <el-form ref="form" :model="form" label-width="80px">
            <el-checkbox-group v-model="form.type" :max="4">
              <el-checkbox label="Smaui"></el-checkbox>
              <el-checkbox label="Bangkok" ></el-checkbox>
              <el-checkbox label="Phuket" ></el-checkbox>
              <el-checkbox label="Pattaya" ></el-checkbox>
            </el-checkbox-group>

            <el-input class="input" v-model="form.name" placeholder="Name"></el-input>

            <el-input class="input" v-model="form.email" placeholder="Email"></el-input>

            <el-input
              class="input"
              v-model="form.telephone"
              placeholder="Telephone"
            ></el-input>
            <el-button class="submitBtn" type="primary" @click="onSubmit">BOOK NOW</el-button>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {bookInspection} from "@/api/contact";

export default {
  name: "",
  props: ["show"],
  data() {
    return {
      form: {
        type: [],
        serverName:"",
        name: "",
        telephone: "",
        email: ""
      },
    };
  },

  methods: {
      onSubmit() {
        this.form.serverName = this.form.type.toString();
        bookInspection(this.form).then(res =>{
          this.hide()
        })
      },
    hide(){
      this.$emit('update:show', !this.show)
    }
  },
};
</script>
<style scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 60%;
  height: 60%;
  background: rgba(0, 0, 0, 0.5);
}
.pop-content {
  width: 700px;
  height: 780px;
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.pop-header {
  color: #fff;
  background-color: #007562;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 183px;
  font-size: 37px;
  /* text-align: center; */
  padding: 48px 71px 31px 84px;
  box-sizing: border-box;
}
.pop-form {
  padding: 50px 113px;
  box-sizing: border-box;
}
.input {
  margin-bottom: 30px;
  height: 62px;
}
/deep/.el-input__inner {
    height: 62px;
}
.submitBtn {
    width: 100%;
    height: 65px;
    background: green;
    border: 1px solid green;
}
.submitBtn :hover {
    background: green
}
.el-button--primary:focus, .el-button--primary:hover {
    background: green !important;
    border: 1px solid green;
}
.el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.el-checkbox {
    width: 34%;
    margin-right: 0;
    margin-bottom: 30px;
}

/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #000 !important;
}
/deep/.el-checkbox .el-checkbox__label {
    margin-left: 15px;
}

/deep/ .is-checked::after{
    background-color: red;
    border: none;
}
/deep/ .is-focus::after{
    background-color: red;
    border: none;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: red;
    border: none;
}
</style>
